import React from 'react'
import SEO from '../components/seo'
import '../components/cacola.css'

const IndexPage = ({ data }) => {
  return (
      <div style={{textAlign: "center", display: "grid", paddingTop: "40vh"}}>
        <div>
          Hold On,
        </div>
        <div>
          Be Strong.
        </div>
      </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
          }
          excerpt
          id
        }
      }
    }
  }
`

export default IndexPage
